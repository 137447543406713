import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NextI18Next, { useTranslation } from '@Lib/i18n';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { SET_FOOTER_MENU_EXTERNAL_URLS } from '@Reducers/sections/actions';
import Icon, { IconSize, IconType } from '@isp/icon';
import { FooterOrder, getFooterMenuExternalUrls, getQrCodeData } from '@Umbraco/footer';
import Logo from '@Static/images/b2b-connect-logo-white.svg';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { RootState } from '@Redux';
import { scrollToOptions } from '@Helpers/common/document';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import classNames from 'classnames';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import DATA_QA from '@Constants/style/data-qa-selector.json';
import { Markets } from '@Constants/market';
import { CustomRouteType } from '@Constants/legal';
import { HOME_LOGO_HEIGHT, HOME_LOGO_WIDTH } from '@Constants/home';
import AppVersion from './AppVersion';
import FooterNewsLetter from './FooterNewsLetter';
import FooterLegal from './FooterLegal';
import FooterLinks from './FooterLinks';
import FooterQR from './FooterQR';
import './index.scss';

export enum FooterContent {
	FULL = 'full',
	ONLY_LEGAL = 'only-legal',
}

interface FooterProps {
	newsLetter?: boolean;
	qr?: boolean;
	appLinks?: boolean;
	legal?: boolean;
	content?: FooterContent;
	customLegalRoute?: CustomRouteType;
}

const Footer: FC<FooterProps> = ({
	appLinks = true,
	newsLetter = true,
	qr = true,
	legal = true,
	content = FooterContent.FULL,
	customLegalRoute = null,
}) => {
	const router = useRouter();
	const { market } = router.query;
	const [qrCode, setQrCode] = useState(null);
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const dispatch = useDispatch();
	const { t } = useTranslation(['common', 'footer']);
	const footerOrder = useSelector<RootState, FooterOrder>(state => state.sections.footerContent.footerOrder);

	const callExternalLinks = () => {
		getFooterMenuExternalUrls(market as string, language).then(footerMenuExternalUrls => {
			dispatch(SET_FOOTER_MENU_EXTERNAL_URLS(footerMenuExternalUrls));
		});
	};

	const getQRData = () => {
		if ((market as string).toLowerCase() === Markets.TURKEY) {
			getQrCodeData(market as string, language).then(qrData => {
				setQrCode(qrData);
			});
		}
	};

	const handleScroll = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);

		scrollToOptions({
			top: document?.body?.getBoundingClientRect().top,
			left: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		callExternalLinks();
		getQRData();
	}, [language]);

	return (
		<div
			className={classNames(
				'bg-black-three p-h-16 p-t-20 p-b-8 fs-16',
				'disp-grid grid--item-gap-8',
				'ncj-footer  pos-abs z-index-5',
				content
			)}
		>
			<div className="ncj-footer__logo">
				<Image src={Logo} alt="B2B Connect" width={HOME_LOGO_WIDTH} height={HOME_LOGO_HEIGHT} />
				<AppVersion />
			</div>
			<div
				className="ncj-footer__top DaimlerCS-Bold disp-flex
				grid--item-gap-1 color-white cursor-pointer"
				data-qa={DATA_QA.FOOTER_BACK_TO_BEGINNING}
				onClick={e => handleScroll(e)}
				id={getComponentId([Pages.GLOBAL, Components.FOOTER, Buttons.LINK])}
				data-hover-azure
			>
				{t('footer:back-to-top')}
				<Icon type={IconType.ARROW_UP_DOUBLE} size={IconSize.SMALLER} />
			</div>
			{newsLetter && <FooterNewsLetter />}
			{appLinks && <FooterLinks qrCode={qrCode} footerOrder={footerOrder} />}
			{legal && <FooterLegal customLegalRoute={customLegalRoute} />}
			{qr && <FooterQR qrCode={qrCode} />}
		</div>
	);
};

export default Footer;
